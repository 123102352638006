import { Grid, Box, useMediaQuery } from "@mui/material";
import { Title } from "./styles";
import ItemsList from "./ItemsList";

export default function OurDifferences() {
  const isDesktop = useMediaQuery("(min-width:1200px)");

  const listDifferences = [
    {
      title: "Cashback na sua conta",
      subtitle:
        "Com a OQtem, cada compra é uma oportunidade para economizar, seja no mercado, na farmácia ou na pizza do final de semana. Aproveite o cashback em todas as suas aquisições e faça seu dinheiro render mais.",
      img: "/images/cashback_account.svg",
    },
    {
      title: "Benefícios ampliados com parcerias",
      subtitle:
        "Possuímos um sistema de afiliações robusto, pensado para atender a necessidade de pequenas e médias empresas. Com a OQTem, você pode receber benefícios como vale alimentação, combustível e até dinheiro, facilitando a gestão de benefícios para seus funcionários.",
      img: "/images/benefits.svg",
    },
    {
      title: "Pagamentos rápidos e seguros",
      subtitle:
        "Além de todos esses benefícios, a OQTem também possibilita que você realize pagamentos via app nos seus estabelecimentos favoritos. Uma maneira prática de pagar, proporcionando mais comodidade no seu dia a dia. ",
      img: "/images/payment.svg",
    },
  ];

  return (
    <Box py={5} id='diferenciais'>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          xl={12}
          pt={2}
          pb={isDesktop ? 0 : 15}
          justifyContent="center"
          textAlign="center"
        >
          <Title>Nossos diferencias</Title>
        </Grid>
        <Box display={"contents"} textAlign="center" justifyContent="center">
          <Grid item xs={10} sm={10} md={10} lg={6} alignContent="center">
            {listDifferences.map(({ title, subtitle, img }: any) => (
              <ItemsList
                key={img}
                title={title}
                subtitle={subtitle}
                img={img}
              />
            ))}
          </Grid>
          <Grid item justifyContent="center" pt={isDesktop ? 15 : 5}>
            <Box>
              <img
                src="/images/phone_app.png"
                alt="Seja um parceiro"
                width={isDesktop ? "110%" : "100%"}
              />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}
