export const ButtonStyle = ({ marginTop = 60 }) => ({
  backgroundColor: "#FFFFFF",
  color: "#ff8a00",
  borderRadius: 30,
  paddingLeft: 40,
  paddingRight: 40,
  fontWeight: "800",
  marginTop: marginTop,
  paddingTop: 13,
  fontFamily: "Josefin Sans, 'sans-serif'",
});
