import {Box, Grid} from "@mui/material";
import Slider from "react-slick";
import useMediaQuery from "@mui/material/useMediaQuery";
import CardStatements from "./Card";
import {Title} from "./styles";

export default function PartnerStatements() {
    const isMobile = useMediaQuery("(min-width: 610px)");
    const checkBg = useMediaQuery("(min-width: 1200px)");

    const list: any = [
        {
            text: "Nós do delivery do Otávio estamos satisfeitos com aplicativo oq tem food  muito fácil e prático de operar , ótimo suporte tendo uma resposta rápida dos colaboradores, nossas vendas tendência de crescimento.",
            person: {name: "Delivery do Otávio", avatar: "/images/statments/statement_4.jpeg", company: ""},
        },
        {
            text: "Utilizo o aplicativo com grande frequência, pois é uma excelente ferramenta que me permite obter cashback em minhas compras. Recomendo fortemente!",
            person: {name: "Itallo Caproni", avatar: "/images/statments/statement_1.jpeg", company: ""},
        },
        {
            text: "Passando aqui para avaliar o novo aplicativo OQtem onde eu consigo acompanhar o status do meu pedido em tempo real e desfrutar de uma entrega rápida, aplicativo intuitivo e fácil de navegar, oferecendo uma ampla seleção de mercados e restaurantes que facilita  no meu dia a dia.",
            person: {name: "Gabriela Paixão", avatar: "/images/statments/statement_2.jpeg", company: ""},
        },
        {
            text: "É, esse aplicativo é dos bons, pois o Felipe e a Ana são uns amorzinhos. Sou grata a eles pela atenção e resposta rápida. Espero que continue assim, o aplicativo também não vejo a hora dele estourar igual aos outros. Sou grata por vocês.",
            person: {name: "Açaí Piradão", avatar: "/images/statments/statement_5.jpeg", company: ""},
        },
        {
            text: "Estou impressionada com a experiência que tive usando o aplicativo OQTEM, a interface é intuitiva e fácil de usar, além disso o processo de fazer o pedido é muito rápido e eficiente e também em toda compra feita você pode obter cashback. Recomendo este aplicativo para quem busca praticidade e qualidade. Parabéns à equipe por oferecer um serviço tão incrível!",
            person: {name: "Samira Alves", avatar: "/images/statments/statement_3.jpeg", company: ""},
        },
    ];

    function SampleNextArrow(props: any) {
        const {className, style, onClick} = props;
        return (
            <img
                className={className}
                style={{
                    ...style,
                    width: isMobile ? "55px" : "40px",
                    height: isMobile ? "55px" : "40px",
                    marginRight: isMobile ? "-50px" : "-8px",
                }}
                onClick={onClick}
                alt="arrow"
                src="images/arrow_foward.png"
            />
        );
    }

    function SamplePrevArrow(props: any) {
        const {className, style, onClick} = props;
        return (
            <img
                className={className}
                onClick={onClick}
                style={{
                    ...style,
                    width: isMobile ? "55px" : "40px",
                    height: isMobile ? "55px" : "40px",
                    marginLeft: isMobile ? "-50px" : "-8px",
                }}
                src="images/arrow_prev.png"
                alt="arrow"
            />
        );
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
    };

    return (
        <Box
            sx={{
                backgroundImage: checkBg
                    ? "url(images/depoimentos_1_1.svg)"
                    : "url(images/depoimentos_2_1.svg)",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
            id='depoimentos'
            py={10}
        >
            <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    item
                    xs={8}
                    sm={8}
                    md={8}
                    xl={10}
                    py={2}
                    justifyContent="center"
                    textAlign="center"
                >
                    <Title>O que dizem nossos clientes e parceiros</Title>
                </Grid>

                <Grid
                    item
                    xs={9}
                    sm={8}
                    md={6.5}
                    lg={5.5}
                    xl={4.5}
                    pt={{xs: 0, sm: 1, md: 2, lg: 3}}
                >
                    {list.length > 1 ? (
                        <Box className="slider-container">
                            <Slider {...settings}>
                                {list.map((it: any, index: number) => (
                                    <CardStatements text={it.text} name={it.person.name} company={it.person.company}
                                                    avatar={it.person.avatar} key={index}/>
                                ))}
                            </Slider>
                        </Box>
                    ) : (
                        <CardStatements text={list[0].text} name={list[0].person.name} company={list[0].person.company}
                                        avatar={list[0].person.avatar}/>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
