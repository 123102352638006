import { Box, Grid, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ButtonStyle } from "../ButtonStyled/styles";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Tittle, SubTittle } from "./styles";

export default function BeAPartner() {
  const isMobile = useMediaQuery("(min-width:1200px)");
  const contactPhone = '+55 (35) 9 2001-8598'
  return (
    <Box bgcolor="#FF8A00" pt={3}>
      <Grid
        display="flex"
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={8}
          md={isMobile ? 7 : 6}
          lg={5.5}
          xl={4.5}
          order={isMobile ? 0 : 2}
          pt={isMobile ? 0 : 4}
        >
          <Box textAlign={isMobile ? "initial" : "center"}>
            <img
              src="/images/be_a_partner.svg"
              alt="Seja um parceiro"
              style={{ marginBottom: "-4px" }}
              width={isMobile ? "90%" : "90%"}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={7.5}
          md={isMobile ? 4.5 : 7}
          lg={5}
          xl={4}
          pt={isMobile ? 0 : 3}
          justifyContent="center"
          textAlign="center"
        >
          <Tittle>Seja um parceiro</Tittle>
          <SubTittle>
            Se você é um lojista ou empresário e está interessado em saber mais
            sobre como a OQTem pode beneficiar o seu negócio, estamos prontos
            para ajudá-lo. Oferecemos soluções personalizadas que podem aumentar
            sua visibilidade, vendas ou gestão dos benefícios dos seus
            colaboradores.
          </SubTittle>
          <SubTittle>
            Para mais informações, entre em contato conosco clicando no botão
            abaixo ou enviando um email para contato@oqtem.com.br
          </SubTittle>
          <Box
            display="flex"
            pt={isMobile ? 6 : 4}
            justifyContent={isMobile ? "initial" : "center"}
          >
            <Button
              type='a'
              href={`https://wa.me/${contactPhone.replace(/\D/g, "")}`}
              disabled={false}
              size="large"
              target="_blank"
              variant="contained"
              sx={{ paddingBottom: "13px", height: "50px" }}
              style={ButtonStyle({ marginTop: 0 })}
              startIcon={<WhatsAppIcon />}
            >
              <div style={{ paddingTop: "6px" }}>QUERO SER UM PARCEIRO</div>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
