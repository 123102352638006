import styled from "styled-components";

export const CardTitle = styled.div`
  text-align: center;
  color: #374151;
  font-size: 19px;
  font-weight: 700;
  margin-top: 20px;

  @media (max-width: 1200px) {
    font-size: 12px;
    margin-top: 45px;
  }
`;
