import { Box } from "@mui/material";
import Layout from "components/Layout";
import Initial from "../components/Initial";
import AboutUs from "../components/AboutUs";
import Counters from "../components/Counters";
import HowItWorks from "../components/HowItWorks";
import Map from "components/Map";
import BeAPartner from "../components/BeAPartner";
import Faq from "../components/Faq";
import OurDifferences from "../components/OurDifferences";
import PartnerStatements from "../components/PartnerStatements";
import Category from "components/Category";

export default function Home() {
  return (
      <Layout>
        <Box width="100%" height="100%">
          <Initial />
          <AboutUs />
          <Counters />
          <HowItWorks />
          <OurDifferences />
          <Category />
          <PartnerStatements />
          <Map />
          <Faq />
          <BeAPartner />
        </Box>
      </Layout>
  );
}
