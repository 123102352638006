import { Box } from "@mui/system";
import Slider from "react-slick";
import CardCategory from "./Card";

export default function Category() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 7.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 0,
    cssEase: "linear",
    adaptiveWidht: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const categorys = [
    { title: "Comida fit", img: "images/category/category_food.png" },
    { title: "Carnes", img: "images/category/category_food_beef.svg" },
    { title: "Pizza", img: "images/category/category_pizza.svg" },
    { title: "Doces", img: "images/category/category_sweet.svg" },
    { title: "Hortifruti", img: "images/category/category_food_fit.svg" },
    { title: "Higiene", img: "images/category/category_hygiene.png" },
    { title: "Conveniência", img: "images/category/category_convenience.png" },
    { title: "Infantil", img: "images/category/category_baby.svg" },
  ];
  return (
    <Box py={5} className="slider-container">
      <Slider {...settings}>
        {categorys.map(({ title, img }, idx) => (
          <CardCategory key={idx} idx={idx} title={title} image={img} />
        ))}
      </Slider>
    </Box>
  );
}
