import { Header } from "./Header";
import Footer from "./Footer";
import { isMobile } from 'react-device-detect';
import AppNotification from "components/AppNotification";
import { Body, Container, Main } from "./styles";

const Layout = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  return (
    <>
      <Container>
        <Body>
        {isMobile && <AppNotification />}
          <Header />
          <Main>{children}</Main>
          <Footer />
        </Body>
      </Container>
    </>
  );
};

export default Layout;
