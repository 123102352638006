import styled from "styled-components";

export const CardTitle = styled.div`
  text-align: initial;
  color: #FF8A00;
  font-size: 21px;
  font-weight: 700;
  margin-top: 10px;
`;

export const CardSubTitle = styled.div`
  text-align: initial;
  color: #8E8181;
  font-size: 16px;
  font-weight: 300;
  padding-top: 18px;
`

export const CardSubTitle2 = styled.div`
  text-align: initial;
  color: #8E8181;
  font-size: 16px;
  font-weight: 300;
`