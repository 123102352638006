import styled from 'styled-components'
export const Title = styled.div`
    text-align: center;
    color: #FFFFFF;
    font-size: 37px;
    font-weight: 700;

  @media (max-width:1200px) {
    text-align: center;
    font-size: 27px;
    font-weight: 700;
  }
`

export const StatmentName = styled.div`
    text-align: initial;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 700;

  @media (max-width:1200px) {
    font-size: 18px;
    font-weight: 300;
  }
`
export const StatmentCompany = styled.i`
    text-align: initial;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 300;

  @media (max-width:1200px) {
    font-size: 16px;
    font-weight: 300;
  }
`

export const StatmentText = styled.i`
    text-align: initial;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 300;

  @media (max-width:1200px) {
    font-size: 18px;
    font-weight: 300;
  }
`