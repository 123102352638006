import { Grid, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import CardHowITWorks from "./Card";
import { TitleHIW, SubTitleWIT } from "./styles";

export default function HowItWorks() {
  const steps: any = [
    {
      title: "Comece aqui",
      description:
        "Faça o download do app na sua loja de aplicativos favorita.",
        image: 'images/img-lojas.svg'
    },
    {
      title: "Cadastre-se",
      description: "Crie sua conta.",
      description2: "Rápido e fácil.",
      image: 'images/img-login.svg'
    },
    {
      title: "Escolha e peça",
      description:
        "Navegue entre restaurantes, mercados, farmácias e realize sua primeira compra.",
        image: 'images/img-itens.svg'
    },
    {
      title: "Ganhe com cada compra",
      description: "Com a OQTem, cada pedido traz economia para o seu bolso.",
      image: 'images/img-cashback.png'
    },
  ];
  const isMobile = useMediaQuery("(min-width:1200px)");
  return (
    <Box py={5} bgcolor="#FFF4E7" id='funciona'>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          xl={10}
          pt={2}
          justifyContent="center"
          textAlign="center"
        >
          <TitleHIW>Como funciona</TitleHIW>
          <SubTitleWIT>
            Em poucos passos você encontra o que precisa e ainda recebe
            cashback.
          </SubTitleWIT>
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        pt={2}
        justifyContent="center"
        alignItems="center"
      >
        {steps.map((it: any, index: number) => (
          <Grid
            item
            xs={10}
            sm={10}
            md={isMobile ? 3 : 7}
            lg={3}
            xl={2.5}
            py={2}
            display="flex"
            justifyContent="center"
            key={index}
            pt={{xs: 3, sm: 3, md: 3, lg:(index + 1)  % 2 === 0 ? 15 : 0}}
          >
            <CardHowITWorks
              title={it.title}
              description={it.description}
              description2={it?.description2}
              image={it.image}
              step={index + 1}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
