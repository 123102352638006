import styled from "styled-components";

export const Title = styled.div`
  text-align: center;
  color: #ff8a00;
  font-size: 35px;
  font-weight: 700;

  @media (max-width: 1200px) {
    font-size: 28px;
    font-weight: 700;
  }
`;
