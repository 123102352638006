import { Box, Grid, Chip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Tittle, SubTittle } from "./styles";

export default function AboutUs() {
  const isMobile = useMediaQuery("(min-width:1200px)");

  return (
    <Box py={isMobile ? 8 : 6} id='sobre' >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10} sm={10} md={isMobile ? 7 : 7} xl={5}>
          <Box textAlign={isMobile ? "initial" : "center"}>
            <img
              src="/images/about_us.png"
              alt="logo"
              width={isMobile ? 518 : 350}
              height={isMobile ? 407 : 275}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={10}
          sm={7}
          md={isMobile ? 4.5 : 7}
          xl={3.5}
          pt={isMobile ? 0 : 3}
          justifyContent="center"
          textAlign="center"
        >
          <Box textAlign={{xs: 'center', sm: 'center', md: isMobile ? 'initial' : 'center', xl: 'initial'}} pb={4}>
            <Chip
              sx={{
                fontWeight: 900,
                color: "#FE4848",
                borderColor: "#FE4848",
                borderWidth: "1px",
                textTransform: 'uppercase'
              }}
              label="sobre nós"
              variant="outlined"
            />
          </Box>
          <Tittle>
            Oferecemos uma solução completa, que vai além do delivery
            tradicional
          </Tittle>
          <SubTittle>
            Entregamos conveniência e economia através da tecnologia.
            Proporcionamos acesso a uma ampla variedade de produtos e serviços
            com o bônus exclusivo do cashback em todas as compras. Incentivamos
            a comparação com o mercado para garantir que você sempre faça um bom
            negócio.
          </SubTittle>
        </Grid>
      </Grid>
    </Box>
  );
}
