import styled from "styled-components";

export const TitleFaq = styled.div`
  text-align: center;
  color: #FF8A00;
  font-size: 35px;
  font-weight: 700;

  @media (max-width: 1200px) {
    font-size: 28px;
    font-weight: 700;
  }
`;

export const AccordionTitle = styled.div`
  text-align: initial;
  color: #FF8A00;
  font-size: 18px;
  font-weight: 700;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

export const AccordionDescription = styled.div`
  text-align: initial;
  color: #8E8181;
  font-size: 16px;
  font-weight: 300;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
`;

