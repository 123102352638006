import { Box, CardContent, useMediaQuery } from "@mui/material";
import { CardStyled, SubTittle, Tittle } from "./styles";

export default function CardMap() {
  const isDesktok = useMediaQuery("(min-width:1200px)");

  return (
    <>
      <CardStyled>
        <CardContent>
          <CardContent>
            <Box textAlign={"initial"}>
              <img
                src={"images/map.svg"}
                alt="Seja um parceiro"
                width={"60px"}
              />
            </Box>
            <Tittle>Área de atuação</Tittle>
            <SubTittle $paddingTop={isDesktok ? 15 : 10}>
              Nossa operação se amplia a cada dia!
            </SubTittle>
            <SubTittle>
              Confira no mapa os locais que já fazem parte da OQtem.
            </SubTittle>
          </CardContent>
        </CardContent>
      </CardStyled>
    </>
  );
}
