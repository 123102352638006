import styled from "styled-components";

export const Container = styled.div`
  background-color: #ff8a00;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
`;

export const ContainerImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  text-align: center;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const MessageInitial = styled.p`
  font-weight: bold;
  font-size: 37px;
  color: ${(props) => props.color || "#fff"};
  @media (max-width: 1200px) {
    font-size: 27px;
  }
`;

export const DotImg = {
  width: "140px",
  height: "auto",
  bottom: "-23px",
};
