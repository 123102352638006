import styled from "styled-components";
export const FooterItem = styled.div`
  text-align: initial;
  color: #fff;
  font-size: 14px;
  font-weight: 700;

  @media (max-width: 1200px) {
    text-align: initial;
    margin-top: 30px;
  }
`;
export const SubItemBox = styled.div`
  margin-top: 15px;
  @media (max-width: 1200px) {
    margin-top: 10px;
  }
`;
export const FooterSubItem = styled.div`
  text-align: initial;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
`;
