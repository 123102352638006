import { Box } from "@mui/material";
import styled from "styled-components";
export const TextCounter = styled.div`
  text-align: initial;
  color: #fe4848;
  font-size: 55px;
  font-weight: 700;

  @media (max-width: 1200px) {
    font-size: 35px;
    font-weight: 700;
  }
`;

export const TextLabel = styled.div`
  color: #8e8181;
  font-size: 23px;
  font-weight: 200;
`;

export const DottedTest = styled(Box)`
  border-color: #dbdbdb !important;
  border-width: 2px;

  @media (max-width: 1200px) {
    border-bottom: dashed;
    padding-bottom: 10px;
  }

  @media (min-width: 1200px) {
    border-right: dashed;
  }
`;
