import {useState} from "react";
import {
    Grid,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {TitleFaq, AccordionTitle, AccordionDescription} from "./styles";

export default function Faq() {
    const [expanded, setExpanded] = useState<number | false>(false);

    const handleChange =
        (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const options: any = [
        {
            title: " O que é OQtem?",
            description:
                "OQTem é um novo aplicativo de delivery que está mudando a maneira como as pessoas pedem delivery. Com cobertura em três cidades e mais de 150 lojas parceiras, oferecemos uma ampla variedade de opções diretamente na palma da sua mão.\n" +
                " Nosso aplicativo intuitivo e eficiente garante uma experiência de entrega rápida e confiável, além de contar com benefício como o cashback para que você possa desfrutar de suas refeições favoritas sempre que quiser. Experimente agora e descubra uma nova forma de conveniência do delivery!",
        },
        {
            title: "O OQtem serve para meu negócio?",
            description:
                "Sim, o OQtem é ótimo para o seu negócio! Não importa se você tem um restaurante, um mercadinho ou até mesmo somente no delivery, o OQtem pode te ajudar a alcançar mais clientes e aumentar suas vendas.\n" +
                "Temos ferramentas que você precisa para gerenciar seus pedidos na plataforma, e ainda conectar você com entregadores parceiros para garantir que seus produtos cheguem aos clientes bem rapidinho...",
        },
        {
            title: "Quais as vantagens do OQtem?",
            description:
                "O OQtem tem várias vantagens. Primeiro, você ganha dinheiro de volta em todas as compras de 3% a 25%. E sabe o que é legal? Esse cashback pode ser sacado em dinheiro ou usado para compras dentro do app.\n" +
                " \n" +
                "Além disso, contamos com parcerias de empresas que permite usar o vale-alimentação dentro do app, em restaurantes, supermercados, postos de gasolina, farmácias entre outros.\n" +
                " \n" +
                "O app é super fácil e interativo. Se precisar de ajuda, o suporte esta a disposição.\n" +
                " \n" +
                "Ah, e tem mais!  Com campanhas incríveis você pode aproveitar para alavancar ainda mais o seu negócio. Legal, né?",
        },
        {
            title: " Do que preciso para me cadastrar?",
            description:
                "Para se cadastrar, é fácil! Você pode se inscrever como pessoa física ou jurídica. Tudo o que você precisa é ter uma conta bancária ou PIX em qualquer banco. Além disso, você precisa de um computador, tablet ou um celular (iOS ou Android). Ah, e não se esqueça da internet para receber os pedidos.\n" +
                " \n" +
                "E se você quiser, pode ter seu próprio motoboy ou optar por um motoboy vinculado ao OQtem. É simples assim!",
        },
        {
            title: "Quais são as taxas cobradas?",
            description:
                "As taxas cobradas são:\n" +
                " \n" +
                "- Taxa de transação: 12% sobre o valor da venda.\n" +
                "- Taxa de venda online: 3,20% para pagamento via QR Code.\n" +
                "- Taxa de pagamento na carteira: 15,20%.\n" +
                " \n" +
                "Você tem isenção de mensalidade por 12 meses. Depois disso, as taxas mensais são:\n" +
                " \n" +
                "- Até R$ 1.800,00 de vendas: R$ 100,00 por mês.\n" +
                "- De R$ 1.800,00 até R$ 5.000,00: R$ 150,00 por mês.\n" +
                "- Acima de R$ 5.000,00: R$ 200,00 por mês.\n" +
                " \n" +
                "Se precisar de adiantamento de pagamento, as taxas são:\n" +
                " \n" +
                "- 7 dias: 2,5%\n" +
                "- 14 dias: 1,90%\n" +
                "- 21 dias: 1,40%.\n" +
                " \n" +
                "Observação: Solicite o adiantamento com pelo menos 3 dias de antecedência.",
        },
        {
            title: "Assinei o contrato, e agora?",
            description:
                "Após assinar o contrato, nossa equipe de suporte entrará em contato para pegar algumas informações importantes para cadastrar sua loja. Eles precisam do nome do produto, descrição, preço, logo da sua loja, endereço, o valor da taxa de entrega e quanto tempo para entrega. Com essas informações. É fácil assim!",
        },
        {
            title: "Como eu acesso o Portal do Parceiro?",
            description:
                "Para acessar o portal do parceiro, é só clicar neste link: https://oqtem.com/vendor-panel/auth/login . Cada parceiro tem seu próprio login e senha. É só colocar suas informações e pronto! Você estará dentro do portal do parceiro, prontinho para começar.",
        },
        {
            title: "Como eu solicito ajuda?",
            description:
                "Se precisar de ajuda, é só entrar em contato com nossa equipe de suporte:\n" +
                "- WhatsApp ou ligação: (35) 9.2000-8598\n" +
                "- E-mail: suporte@oqtem.com\n" +
                " \n" +
                "Estamos aqui para ajudar!",
        },
        {
            title: "Como faço a atualização do meu cardápio via Painel lojista ou via App?",
            description:
                "Nossa equipe de suporte vai te orientar de forma correta, não só para atualizar o cardápio, mas também para explicar sobre o sistema. E se surgirem dúvidas, não se preocupe! Temos vídeos, tutoriais disponíveis explicando passo a passo como fazer as atualizações e como utilizar o sistema. Estamos aqui para te ajudar a aproveitar ao máximo da plataforma!",
        },
        {
            title: "Posso abrir outra loja no OQtem com o mesmo CNPJ?",
            description:
                "Não há problema algum! Pode ser feito tanto com o mesmo CNPJ quanto com o mesmo CPF.",
        }
    ];

    return (
        <Box py={5} bgcolor="#FFF4E7" id='faq'>
            <Grid container justifyContent="center" alignItems="center">
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    xl={12}
                    pt={2}
                    justifyContent="center"
                    textAlign="center"
                >
                    <TitleFaq>Perguntas Frequentes</TitleFaq>
                </Grid>
                <Grid
                    item
                    xs={11}
                    sm={10}
                    md={8}
                    xl={6}
                    pt={4}
                    justifyContent="center"
                    textAlign="center"
                >
                    {options.map((faq: any, index: number) => (
                        <Box
                            key={index}
                            border="1px solid #FF8A00"
                            borderRadius="20px"
                            pt={1}
                            mb={2}
                            px={1}
                        >
                            <Accordion
                                expanded={expanded === index}
                                onChange={handleChange(index)}
                                sx={{
                                    marginBottom: "5px",
                                    backgroundColor: "transparent",
                                    boxShadow: "none",
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        expanded !== index ? (
                                            <AddIcon sx={{color: "#FF8A00"}}/>
                                        ) : (
                                            <RemoveIcon sx={{color: "#FF8A00"}}/>
                                        )
                                    }
                                >
                                    <AccordionTitle>{faq.title}</AccordionTitle>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AccordionDescription>{faq.description}</AccordionDescription>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}
