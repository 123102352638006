import Leaflet from "leaflet";
import { useState } from "react";
import { Marker, Popup, useMap, useMapEvent } from "react-leaflet";

type Locations = {
  text: string;
  latitude: number;
  longitude: number;
  childs?: Locations[] | undefined;
};

export default function Markers({ places }: { places: Locations[] }) {
  const map = useMap();
  const [locationsMap, setLocationsMap] = useState(places);
  var greenIcon = Leaflet.icon({
    iconUrl: "images/pin.svg",
    // shadowUrl: "images/pin.svg",
    iconSize: [50, 70], // size of the icon
    //shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 70], // point of the icon which will correspond to marker's location
    //shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [2, -56], // point from which the popup should open relative to the iconAnchor
  });

  useMapEvent("zoomend", (event: any) => {
    const newLocation: Locations = {
      text: "",
      latitude: event.target.getCenter().lat,
      longitude: event.target.getCenter().lng,
    };
    const nearestLocation: Locations = findNearestLocation(newLocation);
    if (event.target.getZoom() < 13) setLocationsMap(places);
    else {
      const childs: Locations[] = nearestLocation.childs || [];
      setLocationsMap(childs);
    }
  });

  const findNearestLocation = (currentLocation: Locations) => {
    let nearestLocation: Locations = locationsMap[0];
    let nearestDistance = Number.MAX_VALUE;

    places.forEach((location) => {
      const distance = calculateDistance(
        currentLocation.latitude,
        currentLocation.longitude,
        location.latitude,
        location.longitude
      );

      if (distance < nearestDistance) {
        nearestLocation = location;
        nearestDistance = distance;
      }
    });
    return nearestLocation;
  };

  const calculateDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };

  return (
    <>
      (
      {locationsMap.map((place: any, key: any) => (
        <Marker
          key={key}
          alt={key.toString()}
          position={[place.latitude, place.longitude]}
          icon={greenIcon}
          zIndexOffset={key}
          eventHandlers={{
            mouseover: (event) => event.target.openPopup(),
            mouseout: (event) => event.target.closePopup(),
            click: ({ latlng, target }) => {
              const childs: Locations[] =
                places[target.options.zIndexOffset]?.childs || [];

              if (locationsMap[target.options.zIndexOffset]?.childs) {
                setLocationsMap(childs);
              }

              map.setView(latlng, 15);
              target.openPopup();
            },
          }}
        >
          <Popup>{place.text}</Popup>
        </Marker>
      ))}
      )
    </>
  );
}
