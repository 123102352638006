import { Box, Avatar, Stack } from "@mui/material";
import { StatmentText, StatmentName, StatmentCompany } from "./styles";

type PropsCardStatements = {
  text: string;
  name: string;
  company: string;
  avatar: string
};

export default function CardStatements({text, name, company, avatar}:PropsCardStatements) {
  return (
    <Box py={3} px={3} > 
      <Box textAlign="left">
        <img src="/images/“_1.svg" alt="aspas" />
      </Box>
      <Box py={3.5} textAlign="initial">
        <StatmentText>
       {text}
        </StatmentText>
      </Box>
      <Box display='flex' textAlign="right" justifyContent='end'>
        <img src="/images/“_2.svg" alt="aspas" />
      </Box>
      <Stack
        direction="row"
        spacing={1}
        pt={1}
        alignItems="center"
        justifyContent="center"
      >
        <Avatar
          sx={{ border: "3px solid #FFC37C", width: 56, height: 56 }}
          alt="Remy Sharp"
          src={avatar}
        />
        <Box>
          <StatmentName>{name}</StatmentName>
          <StatmentCompany>{company}</StatmentCompany>
        </Box>
      </Stack>
    </Box>
  );
}
