import { Grid, Box } from "@mui/material";
import { SubTittle, Tittle } from "./styles";

export default function ItemsList({ title, subtitle, img }: any) {
  return (
    <Grid item display="flex" xs={10} sm={10} md={12} xl={12} pt={7}>
      <Box textAlign={"initial"}>
        <img src={img} alt="Seja um parceiro" width={"60px"} />
      </Box>
      <Box justifyContent="left" paddingLeft={"20px"}>
        <Tittle>{title}</Tittle>
        <SubTittle>{subtitle}</SubTittle>
      </Box>
    </Grid>
  );
}
