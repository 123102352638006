import { Card, Box, CardContent } from "@mui/material";
import { CardTitle } from "./styles";

type PropsCardHowITWorks = {
  idx: number;
  title: string;
  image: string;
};

export default function CardCategory({
  idx,
  title,
  image,
}: PropsCardHowITWorks) {
  return (
    <Card
      sx={{
        boxShadow: "none",
        paddingTop: (idx + 1) % 2 === 0 ? 9 : 0,
      }}
    >
      <CardContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={{ sm: "110px", md: "110px", lg: "130px", xl: "130px" }}
          textAlign="center"
        >
          <img alt="Imagem passo" src={image} />
        </Box>
        <CardTitle>{title}</CardTitle>
      </CardContent>
    </Card>
  );
}
