import { Card } from "@mui/material";
import styled from "styled-components";

export const Tittle = styled.div`
  text-align: initial;
  color: #fe4848;
  font-size: 34px;
  font-weight: 700;
  padding-top: 20px;

  @media (max-width: 1200px) {
    font-size: 24px;
    font-weight: 700;
    padding-top: 10px;
  }
`;

export const SubTittle = styled.div<{ $paddingTop?: number }>`
  text-align: initial;
  color: #8e8181;
  font-size: 20px;
  font-weight: 200;
  padding-top: ${(props) => props.$paddingTop}px;

  @media (max-width: 1200px) {
    text-align: initial;
    font-size: 18px;
    font-weight: 200;
    padding-top: ${(props) => props.$paddingTop}px;
  }
`;

export const CardStyled = styled(Card)`
  &.MuiCard-root {
    position: absolute;
    max-width: 450px;
    background: #ffffff;
    border: 1px solid #d3e2e5;
    border-radius: 30px;
    padding: 30px 20px;
    margin: 80px 20px;
    z-index: 1;

    @media (max-width: 1200px) {
      padding: 0px 0px;
      margin: 10px 20px;
      width: 90%;
    }
  }
`;
