import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Grid, Stack } from "@mui/material";
import { FooterItem, FooterSubItem, SubItemBox } from "./styles";

export default function Footer() {
  const isMobile = useMediaQuery("(min-width:1200px)");
  const handleClick = (isAndroid: boolean) => {
    if (isAndroid) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.oqtem.cliente",
        "_blank"
      );
    } else {
      window.open(
        "https://apps.apple.com/br/app/oqtem-food/id1671929768",
        "_blank"
      );
    }
  };
  return (
    <Box
      py={4}
      display="flex"
      bgcolor="#F08200"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Grid
        container
        justifyContent="center"
        textAlign={{
          xs: "initial",
          sm: "initial",
          md: isMobile ? "center" : "initial",
          lg: "center",
        }}
      >
        <Grid item xs={10} sm={7.5} md={isMobile ? 3 : 6.5} lg={3} xl={3}>
          <img
            src="/images/logo.svg"
            alt="logo"
            width={isMobile ? 150 : 130}
            height={50}
          />
        </Grid>
        <Grid item xs={10} sm={7.5} md={isMobile ? 3 : 6.5} lg={2.5} xl={3}>
          <Box
            display="grid"
            justifyContent={{
              xs: "initial",
              sm: "initial",
              md: isMobile ? "center" : "initial",
              lg: "center",
            }}
          >
            <FooterItem>CONTATO</FooterItem>
            <SubItemBox>
              <FooterSubItem>(35) 9 2001-8598</FooterSubItem>
              <FooterSubItem>suporte@oqtem.com.br</FooterSubItem>
            </SubItemBox>
          </Box>
        </Grid>
        <Grid item xs={10} sm={7.5} md={isMobile ? 3 : 6.5} lg={2.5} xl={3}>
          <Box
            display="grid"
            justifyContent={{
              xs: "initial",
              sm: "initial",
              md: isMobile ? "center" : "initial",
              lg: "center",
            }}
          >
            <FooterItem>SEJA PARCEIRO</FooterItem>
            <SubItemBox>
              <FooterSubItem>Quero ser parceiro</FooterSubItem>
            </SubItemBox>
          </Box>
        </Grid>
        <Grid item xs={10} sm={7.5} md={isMobile ? 3 : 6.5} lg={3.5} xl={3}>
          <Box
            display="grid"
            justifyContent={{
              xs: "initial",
              sm: "initial",
              md: isMobile ? "center" : "initial",
              lg: "center",
            }}
          >
            <FooterItem>FAÇA DOWNLOAD</FooterItem>
            <SubItemBox>
              <FooterSubItem>
                <Stack direction="row" spacing={2}>
                  <div></div>
                  <img
                    onClick={() => handleClick(true)}
                    style={{ cursor: "pointer" }}
                    src="/images/disponivel-google-play.webp"
                    alt="logo"
                    width="150px"
                  />
                  <img
                    onClick={() => handleClick(false)}
                    style={{ cursor: "pointer" }}
                    src="/images/disponivel-na-app-store.webp"
                    alt="logo"
                    width="150px"
                  />
                </Stack>
              </FooterSubItem>
            </SubItemBox>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
