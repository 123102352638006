import ButtonStyled from "../../../../ButtonStyled";
import { Stack, useMediaQuery } from "@mui/material";

type PropsNavMenu = {
  direction: "row" | "column";
  justify: string;
  spacing: number;
  click: () => void
};

export function NavMenu({ direction, justify, spacing, click }: PropsNavMenu) {
  const isMobile = useMediaQuery("(min-width:1200px)");

  const options: any = [
    { label: "SOBRE", href: "#sobre" },
    { label: "COMO FUNCIONA", href: "#funciona" },
    { label: "DIFERENCIAIS", href: "#diferenciais" },
    { label: "DEPOIMENTOS", href: "#depoimentos" },
    { label: "ÁREA DE ATUAÇÃO", href: "#atuacao" },
    { label: "FAQ", href: "#faq" },
    { label: "DOWNLOADS", href: "https://github.com/oqtem/desktop-lojista/releases" }
  ];

  return (
    <Stack
      direction={direction}
      justifyContent={justify}
      spacing={{ xs: spacing, sm: spacing, md: spacing, lg: spacing, xl: 6 }}
      alignItems={{
        xs: "initial",
        sm: "initial",
        md: "initial",
        lg: "center",
        xl: "center",
      }}
    >
      {options.map((it: any, index: number) => (
        <a
          onClick={() => click()}
          href={it.href}
          key={index}
          style={{
            fontSize: "14px",
            color: "white",
            fontWeight: 500,
            cursor: "pointer",
          }}
        >
          {it.label}
        </a>
      ))}
      <ButtonStyled marginTop={isMobile ? 0 : 50}><a href="https://app-lojista.oqtem.com">PAINEL LOJISTA</a></ButtonStyled>
    </Stack>
  );
}
