import { Box } from "@mui/material";
import { MapContainer, TileLayer } from "react-leaflet";
import Markers from "./Markers";
import CardMap from "./CardMap";
import location from "./data/locations.json";
import "leaflet/dist/leaflet.css";

type Child = {
  text: string;
  latitude: number;
  longitude: number;
};
type Locations = {
  text: string;
  latitude: number;
  longitude: number;
  childs: Child[];
};

export default function Map() {
  const placeLocations: Locations[] = location;
  const mapConfig: {
    center: { lat: number; lng: number };
    zoom: number;
    scrollWheelZoom: boolean;
  } = {
    center: { lat: -21.5, lng: -46.00 },
    zoom: 8,
    scrollWheelZoom: true,
  };

  return (
    <Box
      id="atuacao"
      position={"unset"}
      bgcolor={"#FFF4E7"}
      display={{ xs: "flex", sm: "center", md: "center", lg: "" }}
      justifyContent={{
        xs: "center",
        sm: "center",
        md: "center",
        lg: "initial",
      }}
    >
      <CardMap />
      <MapContainer
        center={mapConfig.center}
        zoom={mapConfig.zoom}
        scrollWheelZoom={mapConfig.scrollWheelZoom}
        markerZoomAnimation={true}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Markers places={placeLocations} />
      </MapContainer>
      ,
    </Box>
  );
}
