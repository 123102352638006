// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  scroll-behavior: smooth;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

.leaflet-container {
  height: 500px;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: sticky;

  @media (max-width: 1200px) {
    height: 80vh;
    width: 100vw;
  }
}

.leaflet-control-container {
  @media (max-width: 1200px) {
    display: none;
  }
}

.leaflet-bottom {
  display: none;
}

.slick-next {
  &::before {
    display: none;
  }
}

.slick-prev {
  &::before {
    display: none;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}`, "",{"version":3,"sources":["webpack://./src/styles/global.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,UAAU;EACV,SAAS;EACT,uCAAuC;EACvC,uBAAuB;AACzB;;AAEA;;EAEE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,SAAS;EACT,UAAU;EACV,gBAAgB;;EAEhB;IACE,YAAY;IACZ,YAAY;EACd;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;CACC;EACC,qBAAqB;CACtB;AACD","sourcesContent":["* {\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  font-family: 'Josefin Sans', sans-serif;\n  scroll-behavior: smooth;\n}\n\nhtml,\nbody {\n  max-width: 100vw;\n  overflow-x: hidden;\n}\n\nbody {\n  color: black;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\n.leaflet-container {\n  height: 500px;\n  width: 100vw;\n  margin: 0;\n  padding: 0;\n  position: sticky;\n\n  @media (max-width: 1200px) {\n    height: 80vh;\n    width: 100vw;\n  }\n}\n\n.leaflet-control-container {\n  @media (max-width: 1200px) {\n    display: none;\n  }\n}\n\n.leaflet-bottom {\n  display: none;\n}\n\n.slick-next {\n  &::before {\n    display: none;\n  }\n}\n\n.slick-prev {\n  &::before {\n    display: none;\n  }\n}\n\n@media screen and (prefers-reduced-motion: reduce) {\n\thtml {\n\t\tscroll-behavior: auto;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
