import styled from 'styled-components'
export const Tittle = styled.div`
    text-align: initial;
    color: #FF8A00;
    font-size: 37px;
    font-weight: 700;

  @media (max-width:1200px) {
    text-align: center;
    font-size: 27px;
    font-weight: 700;
  }
`

export const SubTittle = styled.div`
    text-align: initial;
    color: #8E8181;
    font-size: 23px;
    font-weight: 200;
    padding-top: 45px;

    @media (max-width:1200px) {
      text-align: initial;
      font-size: 20px;
      font-weight: 200;
      padding-top: 30px;
    }
`