import styled from "styled-components";

export const Tittle = styled.div`
  text-align: initial;
  color: #fe4848;
  font-size: 26px;
  font-weight: 700;

  @media (max-width: 1200px) {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const SubTittle = styled.div`
  text-align: initial;
  color: #8e8181;
  font-size: 20px;
  font-weight: 200;
  padding-top: 10px;

  @media (max-width: 1200px) {
    text-align: initial;
    font-size: 18px;
    font-weight: 200;
    padding-top: 10px;
  }
`;
