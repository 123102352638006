import { useState } from "react";
import { Grid, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Drawer } from "./components/Drawer";
import { NavMenu } from "./components/Menu";
import { Container } from "./styles";

export const Header = () => {
  const isMobile = useMediaQuery("(min-width:1200px)");
  const [open, setOpen] = useState(false);

  const handleDrawer = (value: boolean) => {
    setOpen(value);
  };

  return (
    <Container>
      <Grid display="flex" container py={2} px={1} alignItems={"center"}>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          lg={2}
          xl={2}
          textAlign={{
            xs: "left",
            sm: "left",
            md: "left",
            lg: "center",
            xl: "center",
          }}
        >
          <img
            src="/images/logo.svg"
            alt="logo"
            width={isMobile ? 200 : 150}
            height={50}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={10}
          xl={10}
          display={isMobile ? "initial" : "none"}
          alignItems={"center"}
        >
          <NavMenu click={() => handleDrawer(false)} direction="row" spacing={5} justify="center" />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={6}
          display={!isMobile ? "initial" : "none"}
          textAlign="right"
        >
          <IconButton onClick={() => setOpen(true)}>
            <MenuIcon sx={{ color: "white", fontSize: "35px" }} />
          </IconButton>
          <Drawer open={open} handleDrawer={handleDrawer} />
        </Grid>
      </Grid>
    </Container>
  );
};
