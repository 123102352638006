import styled from "styled-components";

export const Title = styled.div`
  color: #000000;
  font-size: 15px;
  font-weight: 600;
`;

export const SubTitle = styled.div`
  color: #9E9E9E;
  font-size: 15px;
  font-weight: 300;
`;

export const DownloadText = styled.div`
  color: #3E7EDE;
  font-size: 17px;
  font-weight: 400;
  cursor: pointer;
`;
