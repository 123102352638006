import { Card, Box, CardContent } from "@mui/material";
import { CardTitle, CardSubTitle, CardSubTitle2 } from "./styles";

type PropsCardHowITWorks = {
  step: number;
  title: string;
  description: string;
  description2?: string;
  image: string;
};

export default function CardHowITWorks({
  step,
  title,
  description,
  description2,
  image,
}: PropsCardHowITWorks) {
  return (
    <Card
      sx={{
        boxShadow: "0px 30px 20px 0px #41301C0A",
        borderRadius: "20px",
        pb: 2,
        width: {
          xs: "348px",
          sm: "348px",
          md: "348px",
          lg: "285px",
          xl: "293px",
        },
      }}
    >
      <CardContent sx={{ px: { xs: 1.5, sm: 1.5, md: 2 } }}>
        <Box py={{ xs: 0.5, sm: 0.5, md: 1.5 }}>
          <Box
            sx={{
              bgcolor: "#FE4848",
              width: "43px",
              height: "43px",
              borderRadius: "50%",
              color: "white",
              fontWeight: 700,
              fontSize: "16px",
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {step}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="150px"
          textAlign="center"
        >
          <img alt="Imagem passo" src={image} />
        </Box>
        <CardTitle>{title}</CardTitle>
        <Box height="50px">
          <CardSubTitle>{description}</CardSubTitle>
          {description2 && <CardSubTitle2>{description2}</CardSubTitle2>}
        </Box>
      </CardContent>
    </Card>
  );
}
